window.onload = () => {
  const topNode = document.querySelector('.top');
  const headerWithMenu = document.querySelector('.header');

  /* Header color change */
  document.onscroll = () => {
    const menuButton = topNode.querySelector('.desktop .menuButton.transparent') 
    const topHeight = topNode.clientHeight;
    const headerWithMenuHeight = headerWithMenu.clientHeight;
    const headerHeight = headerWithMenuHeight - topHeight;
    const isBelowHeader = document.documentElement.scrollTop > headerHeight;
    const isWhite =[...topNode.classList].includes('white');

    if (isBelowHeader && !isWhite) {
      topNode.classList.add('white');
      if(menuButton) {
        menuButton.classList.add('dark');
      }
    } else if (!isBelowHeader && isWhite) {
      topNode.classList.remove('white');
      if (menuButton) {
        menuButton.classList.remove('dark');
      }
    }

  }
}